import React from 'react';
import {Link} from 'react-router-dom';
import { Redirect } from 'react-router';
import {Helmet} from 'react-helmet';

export default function LoginSuccessful({user}) {
    
    if (user===null) {
        return <Redirect to="/" />
    }

    function muokkaus() {
        if (user.id === "1" && user.fname === "OwDB0l" && user.lname === "J45HrN") {
            return <>
                <div className="text-center mt-3">
                    <Link to="/Wx004BBz" className="K_bg"><i className="far fa-arrow-alt-circle-right"></i> Muokkaa ajankohtaista laatikkoa</Link>
                </div>

                <div className="text-center mt-3">
                    <Link to="/CJ21azas" className="K_bg"><i className="far fa-arrow-alt-circle-right"></i> Kirjaudu ulos</Link>
                </div>
            </>;
        }
    }

    return (
        <>
         <Helmet>
                <meta name="robots" content="noindex, noarchive"/>
        </Helmet>
            <div className="row d-flex justify-content-center pb-4" >
                <div className="col-md-6">
                    <div className=" p-4 mb-4">
                        <h3 className="text-center pb-3">Kirjautuminen onnistui!</h3>
                        {muokkaus()}
                    </div>
                </div>
            </div>
        </>    
    )
}
