import React, { useState } from 'react'
import { useHistory } from 'react-router';
import {Helmet} from 'react-helmet';

export default function LoginPage({setUserStorage}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const URL = 'https://kirppisruusunen.fi/ajankohtaista/login.php/';

  let history = useHistory();

  async function login(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);

    const config = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      },
      body: formData
    }

    const response = await fetch(URL, config);
    const json = await response.json();

    if (response.ok) {
      setUserStorage(json);
      history.push('/Qu749uN0');
    } else {
      alert("Kirjautuminen epäonnistui. Tarkista käyttäjänimi ja salasana.");
    }

  }

  
  return (
    <>
    <Helmet>
                <meta name="robots" content="noindex, noarchive"/>
    </Helmet>
      <div className="row d-flex justify-content-center pt-4 pb-5 ">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <div className="px-4 my-3">
            <div className="row d-flex justify-content-center  py-3">
              <form onSubmit={login} className="col-8 col-xxl-6 px-xxl-4">

                <h3 className="text-center">Kirjaudu sisään</h3>
                <div className="mt-4">
                  <label className="visually-hidden">Käyttäjänimi</label>
                  <input id="email" className="form-control mt-2" type="text" placeholder="käyttäjä"
                    value={email} onChange={e => setEmail(e.target.value)} required></input>
                </div>
                <div className="">
                  <label className="visually-hidden">Salasana</label>
                  <input id="password" className="form-control mt-2" type="password" placeholder="salasana"
                    value={password} onChange={e => setPassword(e.target.value)} required></input>
                </div>
                <button className="lbtn mt-3 mb-2 px-5 text-white">Login</button>

              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
