import { Route, Switch, withRouter } from 'react-router-dom';
import React, { useEffect, useState, Suspense, lazy} from 'react';

import './App.css';
import Footer from './Komponentit/Footer';
import Navbar from './Komponentit/Navbar';
import AjankohtaistaCRUD from './Pages/AjankohtaistaCRUD';
import LoginPage from './loginComponents/LoginPage';
import LoginSuccessful from './loginComponents/LoginSuccessful';
import AccountSettings from './loginComponents/AccountSettings';
import Logout from './loginComponents/Logout';
import Page from './Page';
import ReactGA from 'react-ga';


function App() {

  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  useEffect(() => {
    const handleAcceptStatistics = () => {
      if (window.Cookiebot && window.Cookiebot.consent.statistics) {
        const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
        ReactGA.initialize(API_KEY);
      }
    };

    const handleAcceptMarketing = () => {
      if (window.Cookiebot && window.Cookiebot.consent.marketing) { // Facebook's SDK likely falls under 'marketing'
        // The code below adds the Facebook SDK script to your page
        let script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0&appId=157507512759&autoLogAppEvents=1';
        script.nonce = 'dWg25zqO';
        document.body.appendChild(script);
        setCookiesAccepted(true);
      }
    };

    window.addEventListener('CookiebotOnAccept', handleAcceptMarketing);
    window.addEventListener('CookiebotOnAccept', handleAcceptStatistics);

    // Clean up on unmount
    return () => {
      window.removeEventListener('CookiebotOnAccept', handleAcceptStatistics);
      window.removeEventListener('CookiebotOnAccept', handleAcceptMarketing);
    };
  }, []);

  const [isLoaded, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const Etusivu = lazy(() => import('./Pages/Frontpage'));
  const matkailuauto = lazy(() => import('./Pages/Matkailuauto'));
  const ohjeetvuokraajalle =  lazy(() => import('./Pages/OhjeetVuokraajalle'));
  const hinnasto = lazy(() => import('./Pages/Hinnasto'));
  const tietoameista = lazy(() => import('./Pages/TietoaMeista'));
  const tietosuojaseloste = lazy(() => import('./Pages/Tietosuojaseloste'));

  function setUserStorage(e) {
    const newUser = e;
    setUser(newUser);
    sessionStorage.setItem('kayttaja', JSON.stringify(newUser));
  }
  function clearUserStorage() {
    sessionStorage.clear('kayttaja');
    setUser(null);
  }

  useEffect(() => {
    if ('kayttaja' in sessionStorage) {
      setUser(JSON.parse(sessionStorage.getItem('kayttaja')))
    }
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setIsLoading(false);
  }, []);

  if (isLoaded) {
    return (
        <div className="row justify-content-center p-5">
        <div className="col-auto d-block">
            <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
        </div>
        <h2 className="col-auto d-block">Ladataan...</h2>
        </div>
    )
} else {
  return (
  <div className="sivu-bg1 pt-sm-1">
    <div className="pt-2 sivu-bg2 container_c">
        <Navbar user={user}/>
        <Suspense fallback={<div className="row justify-content-center pt-5">
            <div className="col-auto d-block">
                <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
            </div>
            <h2 className="col-auto d-block">Ladataan...</h2>
            </div>}>
        <Switch>
          <Route path="/" render={() => <Etusivu cookiesAccepted={cookiesAccepted} />} exact />
          <Route path="/etusivu" render={() => <Etusivu cookiesAccepted={cookiesAccepted} />} exact />
          <Route path="/ruusunenKirjautuminen" render={() => <LoginPage setUser={setUser} setUserStorage={setUserStorage} />} />
          <Route path="/Qu749uN0" render={() => <LoginSuccessful user={user} />} />
          <Route path="/bj4mROJt" render={() => <AccountSettings user={user} />} />
          <Route path="/CJ21azas" render={() => <Logout setUser={setUser} clearUser={clearUserStorage} />} />

          <Route path="/Matkailuauto" component={matkailuauto} exact/>
          <Route path="/OhjeetVuokraajalle" component={ohjeetvuokraajalle} exact/>
          <Route path="/hinnasto" component={hinnasto} exact/>
          <Route path="/TietoaMeista" component={tietoameista} exact/>
          <Route path="/Wx004BBz" render={(props) => (<Page title="CRUD"><AjankohtaistaCRUD  user={user} /></Page>)} exact/>
          <Route path="/Tietosuojaseloste" component={tietosuojaseloste} exact/>
        </Switch>
        </Suspense>
        <Footer/>
    </div>
  </div>
  );
}
}

export default withRouter(App);
