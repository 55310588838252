import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router';
import {Helmet} from 'react-helmet';

export default function AjankohtaistaCRUD({user}) {
    const [ajankohtaista, setAjankohtaista] = useState([]);
    const [editAjankohtaista, setEditAjankohtaista] = useState(null);
    const[editAjankohtaistaSisalto, setEditAjankohtaistaSisalto] = useState('');
    const [uusiAjankohtainen,setUusiAjankohtainen] = useState("");
    const [tallennettu, setTallennettu] = useState("");
    const[paivitys, setPaivitys] = useState(true);
    const URL = 'https://kirppisruusunen.fi/ajankohtaista/';

    let history = useHistory();

    if (user === null) {
      history.push('/');
    } else if (user.id !== "1" || user.fname !== "OwDB0l" || user.lname !== "J45HrN") {
      history.push('/');
    }

    useEffect(() => {
        let status = 0;
        fetch(URL + "ajankohtaista.php")
            .then(res => {
            status = parseInt(res.status);
            return res.json();
              })
              .then(
                (res) => {
                  if (status === 200) {
                    setAjankohtaista(res);
                  } else {
                    alert(res.error);
                  }
        
                }, (error) => {
                  alert("Virhe ajankohtaista laatikon päivittämisessä.");
                }
              )
        
    }, [paivitys])

    function onRemove(ID) {
        let theID = ID;

        const formData = new FormData();
        formData.append('ID', theID);

        fetch(URL + 'remove.php',
            {
                method: 'POST',
                body: formData
            }
        )
            .then((res) => {
                setPaivitys(!paivitys);
            }
            )
    }

    function updateAjankohtaista(e) {
        e.preventDefault();
        let status = 0;
        fetch(URL + 'updateSisalto.php', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: editAjankohtaista.ajankohtaistaID,
            sisalto: editAjankohtaistaSisalto
          })
        })
        .then(res=> {
          status = parseInt(res.status);
          return res.json();
        })
        .then(
          (res) => {
            if (status === 200) {
              ajankohtaista[(ajankohtaista.findIndex(ajankohtaista=> ajankohtaista.ajankohtaistaID === editAjankohtaista.ajankohtaistaID))].ajankohtaista_sisalto = editAjankohtaistaSisalto;
              setAjankohtaista([...ajankohtaista]); 
              setEditAjankohtaista(null);
              setEditAjankohtaistaSisalto('');
            } else {
              alert(res.error);
            }
          }, (error) => {
            alert(error);
          }
        )
      }

      function setEditedAjankohtaista(merkinta) {
        setEditAjankohtaista(merkinta);
        setEditAjankohtaistaSisalto(merkinta?.ajankohtaista_sisalto);
      }

      function add(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('sisalto', uusiAjankohtainen);

        fetch(URL + 'lisaaAjankohtainen.php',
            {
                method: 'POST',
                body: formData
            }
        )
            .then((res) => {
            if (uusiAjankohtainen !== '') {
                setTallennettu("Tiedot tallennettu!");
                setUusiAjankohtainen('');
                setPaivitys(!paivitys);
            }
            }
            )
    }

    return (
        <div className="container">
           <Helmet>
                <meta name="robots" content="noindex, noarchive"/>
           </Helmet>
            <section className="row p-3">
            <h3 className="text-center pb-2">Merkinnän lisäys ajankohtaista sivulle:</h3>
            <div className="col-md-6 ohjeet_ajankohtaista">
                <div className="mx-lg-2">
                    <h2 className="text-center fontti mb-4 pt-1">Sivun näkymä</h2>
                    <section className="artikkelit_AK m-auto loota1 border border-dark">
                    <h4 className="fontti pl-1 ps-4 p-3 mt-1 bold "><u>Viimeisimmät</u>
                    <span style={{color: "black"}} className="p-2"><i className="fas fa-calendar-alt"></i></span></h4>

                    <ul className="loota2 py-2">
                    {ajankohtaista.map(ajankohtaista => (
                        <li className="mx-2 mb-2">{ajankohtaista.ajankohtaista_sisalto}<span className="text-muted p-3">{ajankohtaista.kirjoitettu}</span></li>
                    ))}
                    </ul>
                    </section>
                </div>
                <div>
                </div>
            </div>
            <div className="col-md-6 border-start border-primary">
                <h4 className="text-center">Ohjeita merkinnän tekemiseen:</h4>
                <p>Merkinnän maksimipituus on 250 merkkiä. Kirjoitettuasi merkinnän, paina
                painiketta <span className="btn btn-outline-primary">LISÄÄ</span> jolloin
                merkintä siirtyy ajankohtaista laatikkoon, poistaen vanhimman merkinnän, jos merkintöjä on yli 7.
                </p>
                <h4>Lisää merkintä:</h4>
                <textarea rows="4" className="form-control" maxLength="250" value={uusiAjankohtainen} placeholder="Maksimipituus on 250 merkkiä" onChange={e => setUusiAjankohtainen(e.target.value)}/>
                <button onClick={add} className="mt-3 btn btn-outline-primary btn-lg">Lisää</button>
            </div>
            <div className="col-12 text-muted m-0 p-0 text-center">
                        <p>{tallennettu}</p>
            </div>
            </section>
            <section className="row taulu">
                <h2 className="text-center">Merkintöjen poisto ja muokkaus:</h2>
                <table>
                    <thead>
                    <tr>
                        <th>Päivämäärä</th>
                        <th>Sisältö</th>
                    </tr>
                    </thead>

                    <tbody>
                    {ajankohtaista.map(ajankohtaista => (
                    <tr key={ajankohtaista.ajankohtaistaID}>
                        <td>
                        {ajankohtaista.kirjoitettu}
                        </td>

                        <td>
                        {editAjankohtaista?.ajankohtaistaID !== ajankohtaista.ajankohtaistaID &&
                        ajankohtaista.ajankohtaista_sisalto
                        }

                        {editAjankohtaista?.ajankohtaistaID === ajankohtaista.ajankohtaistaID && 
                        <form onSubmit={updateAjankohtaista}>
                        <input type="text" size="50" value={editAjankohtaistaSisalto} onChange={e => setEditAjankohtaistaSisalto(e.target.value)}/>
                        <button className="btn btn-primary mx-2">Save</button>
                        <button className="btn btn-primary mx-2" type="button" onClick={() => setEditedAjankohtaista(null)}>Cancel</button>
                        </form>
                        }
                        <br/>
                        <button id="btnDelete" className="btn btn-danger"
                        onClick={() => { if (window.confirm('Oletko varma, että haluat poistaa seuraavan merkinnän:' + ajankohtaista.ajankohtaista_sisalto + '?')) onRemove(ajankohtaista.ajankohtaistaID) } }
                        >Poista
                        </button>

                        {editAjankohtaista === null && 
                        <button id="btnEdit" className="btn btn-primary" onClick={() => setEditedAjankohtaista(ajankohtaista)}>
                        Muokkaa
                        </button>
                        }
                        
                        </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </section>
        </div>
    )
}
