import React from 'react'

export default function Footer() {
    return (
        <footer className="footer row pt-3 pt-xxl-2">
            <div className="col-xxl-2"></div>
            <div className="col-sm-6 col-md-3 col-xxl-2 text-break text-center mt-4 pt-2 mt-md-3 pt-md-2 mt-lg-3 pt-lg-3 px-lg-0">
                <h3>Osoite</h3>
                <p className="mb-1">Viljavarastontie 1</p>
                <p>84100 Ylivieska</p>
            </div>

            <div className="col-sm-6 col-md-3 col-xxl-2 text-break text-center mt-1 mt-sm-4 mt-md-1 pt-2 mt-md-3 pt-md-2 my-lg-3 pt-lg-3 px-lg-0">
                <h3>Yhteystiedot</h3>
                <p className="mb-1">+358 405957281</p>
                <p style={{fontsize: 'larger'}}>kirppis.ruusunen@gmail.com</p>
            </div>

            <div className="col-sm-6 col-md-3 col-xxl-2 text-break text-center mt-1 pt-2 mt-md-3 pt-md-2 mt-lg-3 pt-lg-3 px-lg-0">
                <h3>Aukioloajat</h3>
                <p className="mb-1">Ma-Pe 10-18</p>
                <p>La 10-15, Su 12-16</p>
            </div>

            <div className="col-sm-6 col-md-3 col-xxl-2 text-break text-center mt-1 pt-2 mt-md-3 pt-md-2 mt-lg-3 pt-lg-3 px-lg-0">
                <h3 className="mb-3">Seuraa meitä</h3>
                <p>
                    {/* FB icon */}
                    <a href="https://fi-fi.facebook.com/Kirppis.Ruusunen" target="_blank" rel="noreferrer"><i className="mx-2 fab fa-facebook fa-2x text-white"></i></a>
                    {/* IG icon */}
                    <a href="https://www.instagram.com/kirppisruusunen/?hl=fi" target="_blank" rel="noreferrer"><i className="mx-2 fab fa-instagram fa-2x text-white"></i></a></p>
            </div>
            <div className="col-xxl-2"></div>


            <div className="col-12 mt-4 mt-md-2 mt-lg-1  text-center text-muted mb-1">
                © Aleksi Hannula, Joni Anttila | <a href="Tietosuojaseloste">Rekisteri- ja tietosuojaseloste</a>
            </div>
        </footer>
    )
}