import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function Navbar({user}) {
    function userName() {
        if (user !== null) {
            return "Admin";
        }
        
    }
    function dNone() {
      if (user === null) {
          return "d-none";
      }
      
  }

  function listaus() {
    if (user !== null) {
        return  <div className="text-center border lead py-2">
                    <button className="loginLinkki btn btn-lg btn-outline-primary" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-user-circle-o d-inline" aria-hidden="true"></i>
                        <div className="kayttaja d-inline"> {userName()}</div>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                          <li><Link className="link dropdown-item" to="/bj4mROJt">Työkalut</Link></li>
                          <li><hr className="dropdown-divider"/></li>
                          <li><Link className="link dropdown-item" to="/CJ21azas">Kirjaudu ulos</Link></li>
                    </ul>
                </div>;
    }
    
}


    return (
      <div className="pb-1">
        <nav className="navbar navbar-expand-xl navbar-light bg-light">
          <div className="container-fluid pb-1 m-2">
            <a className="navbar-brand" href="/">
              <img className="logo img-fluid" src={require("../img/logo.png").default} alt="Kirppis Ruusunen logo"></img>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse ms-md-4" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink exact activeClassName="active" className="nav-link " to="/">Etusivu</NavLink>
                </li>
                <li className="nav-item d-none d-xl-block">
                    <p className="nav-link m-0 fst-italic">|</p>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="hinnasto">Hinnasto</NavLink>
                </li>
                <li className="nav-item d-none d-xl-block">
                    <p className="nav-link m-0 fst-italic">|</p>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="OhjeetVuokraajalle">Ohjeet vuokraajalle</NavLink>
                </li>
                <li className="nav-item d-none d-xl-block">
                    <p className="nav-link m-0 fst-italic">|</p>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="TietoaMeista">Tietoa Meistä</NavLink>
                </li>
                
                
              </ul>
              
            </div>
            <span className="d-flex text-center auki fst-italic  poytavaraus_nav">
              <h3 className="fontti m-0 me-3 ">Auki: </h3>
              <p className="m-0 fw-bold">Arkisin 10-18, La 10-15, Su 12-16</p>
            </span>
          </div>
        </nav>
        <div className={dNone()}>
          {listaus()}
        </div>
      </div>
    )
}
