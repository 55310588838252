import React,{useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

export default function Logout({clearUser}) {
  useEffect(() => {
    async function logout() {
      
      const config = {
        method: 'GET',
        credentials: 'include'
      }
  
      const URL = 'https://kirppisruusunen.fi/ajankohtaista/logout.php/';
      try {
        await fetch(URL,config);
        clearUser();
      } catch (error) {
        alert(error);
      }
    }
    logout();
  }, [clearUser])


  return (
    <>
     <Helmet>
                <meta name="robots" content="noindex, noarchive"/>
    </Helmet>
      <div className="row d-flex justify-content-center  pb-4">
          <div className="col-md-8 col-lg-6">
              <div className="K_bg p-4 my-3">
                  <h3 className="text-center pb-3">Olet kirjautunut ulos!</h3>
                  <div className="text-center mt-3">
                    <Link className="K_bg " to="/ruusunenKirjautuminen"><i className="far fa-arrow-alt-circle-right"></i> Kirjaudu sisään</Link>
                  </div>
                  <div className="text-center mt-3">
                    <Link className="K_bg " to="/"><i className="far fa-arrow-alt-circle-right"></i> Takaisin etusivulle</Link>
                  </div>
              </div>
          </div>
      </div>
    </>
    
  )
}